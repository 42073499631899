
import Vue from "vue";
export default Vue.extend({
  props: {
    isLead: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    if (this.registrationDisabled) {
      this.$appNotify({
        message: "Registration is disabled.",
        type: "info",
        title: "Info"
      });
      this.$router.push("/login").catch(() => {});
    }
  },
  computed: {
    confirmAccount(): boolean {
      return false;
    },
    requireApproval(): boolean {
      return true;
    },
    registrationDisabled(): boolean {
      return false;
    }
  }
});
